<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"
                 :key="key" />
  </div>
</template>

<style>
@import "./assets/css/base.css";
</style>
<script>
export default {
  data () {
    return {
      listIndex: 0,
      list: []
    }
  },
  computed: {
    key () {
      return this.$route.fullPath
    }
  },
}
</script>