import axios from 'axios'
import Qs from 'qs'
import store from '../../store/index'
import router from '../../router'
import { Toast } from 'vant';

// axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
// export const baseurl = 'http://localhost:8080/'
export const baseurl = 'http://jg.tzgcs360.com'
axios.defaults.baseURL = baseurl

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.defaults.transformRequest = [
  function (data, config) {
    // 对 data 进行任意转换处理
    if (config['Content-Type'] === 'multipart/form-data') {
      return data
    }
    return Qs.stringify(data)
  },
]

axios.interceptors.request.use(function (config) {
  let token = window.localStorage.getItem("token")
  if (token) {
    config.headers.common['Authorization'] = "Bearer " + token;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    console.log(error);
    if (error.response.status) {
      console.log(error.response)
      if (error.response.status == 400) {
        Toast.fail('网络延迟，请刷新该页面')
        // axios
        //   .get('/api/user/check_login_status', {})
        //   .then((res) => {
        //     if (res.data.status == 401) {
        //       router.replace({
        //         path: '/login',
        //         query: {}
        //       });
        //     }
        //   })
      } else if (error.response.status == 401) {
        // console.log(error);
        window.localStorage.removeItem("token");
        // if (error.response.url != '/apimobile/ceshi_login') {
        //   axios
        //     .post('/apimobile/ceshi_login', {})
        //     .then((res) => {
        //       console.log(res);
        //       window.localStorage.setItem("user", JSON.stringify(res.data.data.user));
        //       if (res.data.status == 1) {
        //         window.localStorage.setItem("token", res.data.data.token);
        //         location.reload();
        //       }
        //     })
        // }
        router.replace({
          path: '/index',
          query: {}
        });
      }
      return Promise.reject(error.response);
    }
  })


export function get (url, params = {}) {
  Toast.loading({
    message: '加载中...',
    duration: 100000,
    forbidClick: true,
  });
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((resp) => {
        Toast.clear();
        resolve(resp.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function getJosn (url, params = {}) {
  // console.log(params);
  // params.aaa=1
  Toast.loading({
    message: '加载中...',
    duration: 100000,
    forbidClick: true,
  });
  return new Promise((resolve, reject) => {
    axios
      .get('https://tzfemale.tzwomen.org.cn/' + url, {
        params: params,
      })
      .then((resp) => {
        Toast.clear();
        resolve(resp.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function post (url, data = {}) {
  Toast.loading({
    message: '加载中...',
    duration: 100000,
    forbidClick: true,
  });
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((resp) => {
        Toast.clear();
        resolve(resp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const upvideoconfig = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export function uploadfile (url, data) {
  return new Promise((resolve, reject) => {
    upvideoconfig
      .post(url, data)
      .then((resp) => {
        resolve(resp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
