import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import { get, post, uploadfile, baseurl } from './assets/js/ajax'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Image as VanImage } from 'vant';
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)
Vue.use(VanImage);
Vue.use(VueAwesomeSwiper)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// ajax
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$uploadfile = uploadfile
Vue.prototype.$url = baseurl
Vue.prototype.$imgUrl = 'http://jg.tzgcs360.com'

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
