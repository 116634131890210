import Vue from 'vue'
import VueRouter from 'vue-router'

const index = () => import('../views/index');
const content = () => import('../views/content');
const gsContent = () => import('../views/gsContent');
const rw = () => import('../views/rw');
const rc = () => import('../views/rc');

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/index',
    component: index,
    name: index,
    meta: { hidden: true, title: "帮帮共富", keepAlive: true }
  },
  {
    path: '/content',
    component: content,
    name: content,
    meta: { hidden: true, title: "产品详情", keepAlive: true }
  },
  {
    path: '/gsContent',
    component: gsContent,
    name: gsContent,
    meta: { hidden: true, title: "公司详情", keepAlive: true }
  },
  {
    path: '/rw',
    component: rw,
    name: rw,
    meta: { hidden: true, title: "产品", keepAlive: true }
  },
  {
    path: '/rc',
    component: rc,
    name: rc,
    meta: { hidden: true, title: "人才", keepAlive: true }
  },
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  base: '/gw',
  routes,
  scrollBehavior (to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
